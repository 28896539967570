#CTA {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .span {
        font-size: 16px;
        color: var(--text-gray);

        .highlight {
            color: white;
        }
    }

    .span-limiter {
        width: 443px;
        text-align: center;
    }

    .data {
        font-size: 64px;
        font-weight: 100;
        color: white;
        margin: 10px 0;
        cursor: pointer;
    }

    .data_mount {
        color: #cab1fa;
        text-shadow: 1px 0 20px rgba(0, 255, 115, .5);
        transition: all .5s ease;
    }

    .data_unmount {
        color: white;
        text-shadow: 0px 0 0px rgba(0, 255, 115, 0);
        transition: all .5s ease;
    }

    .data_hovered {
        color:#ae87f5;
        font-weight: 900;
        transition: font-weight .4s ease;
        text-shadow: 1px 0 15px rgba(0, 255, 115, 0.5);
    }

    .data_hovered-adjacent {
        color:#cab1fa;
        font-weight: 400;
        transition: font-weight .4s ease;
        text-shadow: 1px 0 15px rgba(0, 255, 115, 0.2);
    }

    @media screen and (max-width: 720px) {
        margin-top: 50px;

        .data {
            font-size: 36px;
            font-weight: 500;
            color: white;
            margin: 10px 0;
        }
    }

    @media screen and (max-width: 480px) {
        margin-top: 30px;

        .data {
            font-size: 24px;
            font-weight: 500;
            color: white;
            margin: 10px 0;
        }

        .span-limiter {
            width: 250px;
            text-align: center;
        }

        .span {
            font-size: 14px;
        }
    }
}