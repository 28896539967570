#Experience {
    position: relative;

    &:hover {
        .timeline-dot {
            .timeline-dot__inner {
                transition: all .5s ease;
                background-color: #955efc;
                box-shadow: 1px 0 20px rgba(0, 255, 115, 1);
                filter: blur(3px);
            }
        }

    }

    .title {
        font-size: 28px;
        color: white;
        font-weight: bold;
    }

    .subtitle {
        font-size: 28px;
        color: white;
        font-weight: 200;
    }

    .content {
        font-size: 18px;
        font-weight: 300;
        color: var(--text-gray);
        overflow-wrap: break-word;

    }

    .header {
        margin-bottom: 15px;
    }

    .image-container {
        margin-top: 20px;
    }

    .inner-container {
        flex-direction: column;
        margin-bottom: 90px;
    }

    .image {
        width: 150px;
        height: 160px;
        border-radius: 10px;
        margin-right: 20px;
        transition: all .7s ease-in-out;
        object-fit: cover;

        &:hover {
            width: 600px;
            height: 640px;
            box-shadow: 0 0 15px rgba(0, 255, 115, 0.2);
        }
    }

    .timeline-dot {
        position: absolute;
        left: -91px ;
        top: 6px;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: var(--base-color-1);
        border: 2px solid var(--stroke-gray);
        display: flex;
        justify-content: center;
        align-items: center;

        .timeline-dot__inner {
            background-color: #ae87f5;
            box-shadow: 0 0 13px rgba(0, 255, 115, 0.3);
            height: 8px;
            width: 8px;
            border-radius: 100%;
            filter: blur(8px);
        }
    }

    @media screen and (max-width: 1050px) {
        .timeline-dot {
            display: none;
        }
    }

    @media screen and (max-width: 720px) {
        .content {
            font-size: 14px;
        }

        .inner-container {
            flex-direction: column;
            margin-bottom: 50px;
        }
    }

    @media screen and (max-width: 680px) {
        .image {
            &:hover {
                width: 400px;
                height: 400px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .image {
            width: 100px;
            height: 110px;
                &:hover {
                    width: 110px;
                    height: 110px;
                }
        }

        .title, .subtitle {
            font-size: 20px;

        }
    }
}