#Portfolio {
    display: flex;
    align-items: center;
    justify-content: center;   
    flex-direction: column;
    margin: 80px 0px;
    
    .filters-container {
        position: relative;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 5px;

        .filter {
            cursor: pointer;
            color: rgba($color: #FFFFFF, $alpha: 0.1);
            transition: all .3s ease-in;

            &:hover {
                color: var(--text-gray);
                transform: translateY(-2px);
            }
        }

        .filter-active {
            cursor: pointer;
            color: rgba($color: #FFFFFF, $alpha: 1.0);
            transition: all .3s ease-in;
        }

        .delete-filters {
            cursor: pointer;
            position: absolute;
            right: -25px;
        }

        .text {
            color: #FFFFFF;
        }
    }

    .items-container {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 480px) {
        margin: 30px 0px;

        .filters-container {
            font-size: 12px;
        }
    }
}