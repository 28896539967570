#TLDR { 
    display: flex;
    align-items: center;
    justify-content: center;   
    flex-direction: column;
    margin: 80px 0px;

    .items-container {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .tldr-icon {
        font-size: 50px;
    }

    .headliner {
        color: var(--text-gray);
        font-weight: 300;
        font-size: 16px;
        width: 700px;
        text-align: center;
        margin-bottom: 30px;
    }

    .highlight {
        font-weight: 600;
    }

    
    @media screen and (max-width: 720px) {
        .headliner {
            width: 470px;
        }
    }

    @media screen and (max-width: 480px) {
        margin: 30px 0px;

        .headliner {
            width: 80%;
            font-size: 12px;
        }
    }
}