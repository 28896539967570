#header {
    height: 60px;
    width: 100%;
    border-bottom: black solid 1px;
    color: var(--text-gray);
    font-size: 12px;
    padding: 0px 80px;

    .header-inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        font-size: 20px;
        font-weight: 900;
        cursor: pointer;
        transition: all .3s ease-in;

        &:hover {
            color: white;
        }
    }

    .menu {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }

    .link {
        font-weight: 500;
        cursor: pointer;
        transition: all .3s ease-in;

        &:hover {
            color: white;
        }
    }

    @media screen and (max-width: 480px) {
        padding: 0px 15px;
    }
}