.app {
    background-color: var(--base-color-1);
    font-family: var(--font-base);
    display: flex;
    flex-direction: column;
    justify-items: space-between;
    min-height: 100vh;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.app-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}

.d-flex {
    display: flex;
}